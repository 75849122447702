.logo {
  text-align: left;
  margin-top: 40px;
  padding-left: 15px;
}

.logo_brand {
  text-align: center;
}

.logo_brand img {
  height: 50px;
  width: 95px;
  object-fit: contain;
}

.logo img {
  height: 50px;
  width: 150px;
  object-fit: contain;
  padding-left: 30px;
}

.side_menu_img {
  background-color: red;
}

.sb_arrow {
  position: absolute;
  right: 10px;
  top: 15px;
}

.sb_image_active {
  display: none;
}

.list_group_flush {
  margin-left: 35px;
  border-left: 1px solid #d5d5d8;
}

.management_sidebar {
  background-color: #f2f0fb;
  height: 105vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  position: relative;
  scrollbar-width: none;
}

.management_sidebar::-webkit-scrollbar {
  display: none;
}

.management_sidebar .list_group {
  margin-top: 20px;
  margin-bottom: 180px;
  padding: 0px 15px;
}

.management_sidebar .list_group_item {
  background-color: #f2f0fb;
  border-color: transparent !important;
  padding: 8px 0px;
  font: 600 14px Poppins;
  color: #2d2424;
  margin-top: 15px;
  text-decoration: none;
  border-left: 5px solid;
  border-radius: 0px 5px 5px 0px;
  position: relative;
}

.management_sidebar .list_group_item.active {
  background-color: transparent;
}

.management_sidebar .list_group_item.active img {
  box-sizing: content-box;
  padding: 10px;
  background-color: #f6efe7;
  border-radius: 4px;
  margin-left: 15px;
  margin-right: 15px;
}

.management_sidebar .list_group_item:hover,
.management_sidebar .list_group_item.menu_active {
  color: #22205e;
  /* background: transparent
    linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
  border-left: 5px solid #22205e !important; */
}

.management_sidebar .list_group_item:hover .sb_image,
.management_sidebar .list_group_item.menu_active .sb_image {
  display: none;
}

.management_sidebar .list_group_item:hover .sb_image_active,
.management_sidebar .list_group_item.menu_active .sb_image_active {
  display: inline-block !important;
}

.management_sidebar .list_group_sub_item.menu_active {
  color: #22205e;
  background: transparent
    linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
}

.management_sidebar .list_group_item .sb_image,
.management_sidebar .list_group_item .sb_image_active {
  /* height: 18px; */
  height: 30px;
  /* width: 20px; */
  margin: 0px 20px;
  /* vertical-align: sub; */
}

.management_sidebar .list_group_sub_item {
  /* padding-left: 90px !important; */
  border-color: transparent !important;
  padding: 8px 10px 8px 22px;
  color: #22205e;
  text-decoration: none;
  font: 500 14px Poppins;
  border-left: 5px solid;
  border-radius: 0px 5px 5px 0px;
}

.management_sidebar .list_group_sub_item:hover {
  color: #4c52b8;
  background: transparent
    linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
  /* box-shadow: 0px 15px 30px #4C52B833; */
  /* border-left: 5px solid #4C52B8 !important; */
}

.management_sidebar .admin_sidebar_footer {
  margin: 0 25px;
  position: relative;
bottom: 30px;
}

.management_sidebar .admin_sidebar_footer h4 {
  font: 700 12px/30px Poppins;
  color: #32303e;
  margin-bottom: 0px !important;
}

.management_sidebar .admin_sidebar_footer h1 {
  font: 600 14px/30px Poppins;
  color: #000;
  margin-bottom: 0px !important;
}

.management_sidebar .admin_sidebar_footer span {
  font: 500 8px/14px Poppins;
  color: #32303e;
  display: block;
}

.showIn_mobile {
  display: none;
  padding: 0px !important;
  margin: 0px !important;
}

button {
  outline: 0px !important;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: none !important;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler {
  text-decoration: none;
  outline: none !important;
  box-shadow: 0 0 0 0.25rem;
}

.side_recommendation{
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

@media (max-width: 1024px) {
  .management_sidebar .list_group {
    margin-top: 20px;
    margin-bottom: 180px;
    padding: 0px 10px;
  }
}

@media (max-width: 769px) {
  .showIn_mobile {
    display: none !important;
  }
  .management_sidebar {
    display: visible !important;
  }
}

@media (max-width: 759px) and (min-width: 430px) {
  .showIn_mobile {
    display: visible !important;
  }
  .management_sidebar {
    display: none !important;
  }
}

@media (max-width: 426px) {
  .showIn_mobile {
    display: visible;
  }
  .management_sidebar {
    display: none !important;
  }
}

@media (max-width: 2561px) and (min-width: 1445px) {
  .management_sidebar .admin_sidebar_footer {
    margin: 0 25px;
    position: absolute;
    left: 10px;
    bottom: 50px;
  }
}
