.btn_login {
  width: 100%;
  font: 400 16px/20px Poppins;
  color: #ffffff;
  text-transform: uppercase;
  padding: 16px 32px;
  background-color: #353b3b;
  border-radius: 3.7px !important;
  margin-bottom: 3px;
  outline: none !important;
  border: none;
  margin-top: 5px;
}

.btn_login:focus {
  box-shadow: none !important;
}

.btn_login:hover {
  color: #ffffff !important;
}

.form_control:focus {
  border: 1px solid #dedede !important;
  box-shadow: none !important;
}

.back_toLogin_color {
  color: #5c5da1 !important;
  cursor: pointer;
}

.verify_btn {
  width: 100%;
  margin-top: 30px;
}

.login_bg {
  background-image: url("../../assets/img/login_bg.png");
  width: 100%;
  /* height: 100vh; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.heading_text {
  font: normal normal bold 36px/34px Poppins;
  color: #fff;
  text-align: left;
  margin-bottom: 20px;
}

p {
  text-align: left;
  font: normal normal normal 18px/10px Poppins;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.login_bg_content_p_2 {
  position: absolute;
  text-align: center;
  font: normal normal normal 16px/24px Poppins;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  margin-bottom: 145px;
  margin-left: 169px;
}

.forgot_image {
  height: 100vh;
  width: auto;
}

.login_bg_forgot {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.bg_layout {
  background-image: url(../../assets/img/layout1.png);
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  background-position: center;
}
.bg_layout1 {
  background-image: url(../../assets/img/layout2.png);
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  background-position: center;
}
.bg_layout2 {
  background-image: url(../../assets/img/layout3.png);
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  background-position: center;
}
.bg_layout3 {
  background-image: url(../../assets/img/layout4.png);
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  background-position: center;
}

.login_banner_img {
  background-image: url(../../assets/img/login_banner_img.png);
  background-size: cover;
  width: 100%;
  height: 100vh;
  /* height: 100vh; */
}
.login_content_margin {
  padding-top: 160px;
  padding-left: 160px;
}

.login {
  width: 100%;
  max-width: 450px;
  padding: 100px 55px 28px 55px;
  margin: 12% auto 0;
}

.logo_img {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 200px;
}
.logo_img img {
  width: 104px;
  height: 53px;
}

.login .title_text {
  font:  25px/34px Poppins;
  color: #32303e;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 600;
}

.login .title_text1 {
  font: normal normal bold 21px/15px Poppins;
  color: #32303e;
  margin-bottom: 15px;
  text-align: center;
}

.login .welcome_text {
  text-align: center;
  font: normal normal normal 16px/24px Poppins;
  letter-spacing: 0px;
  color: #959DB8;
  opacity: 1;
  margin-bottom: 15px;
}

.login .form_group {
  margin-bottom: 20px !important;
}

.login .form_control {
  border: 1px solid #dedede;
  border-radius: 10px !important;
  background-color: #ffffff !important;
  height: 47px;
  margin-bottom: 5px;
}

.login .form_control::-moz-placeholder {
  text-align: left;
  font: normal normal normal 14px/16px Poppins;
  letter-spacing: 0px;
  color: #9a9a9a;
  opacity: 1;
}

.login .form_control:-ms-input-placeholder {
  font: 500 12px/16px Poppins;
  color: #9a9a9a;
}

.login .form_control::placeholder {
  font: 500 12px/16px Poppins;
  color: #9a9a9a;
}

.login .error_text {
  font: 500 12px/16px Poppins;
  color: #dc3545;
  visibility: hidden;
  text-align: left;
}

.login .error_text.active {
  visibility: visible;
  text-align: left;
}

.login .password_icon {
  position: relative;
}
.inputPosition {
  position: relative;
}

.form_group .password_icon1 {
  position: absolute;
  top: 303px;
  right: 805px;
}

.login .password_icon img {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.form_group .password_icon1 img {
  position: absolute;
  top: 37px;
  right: 10px;
  cursor: pointer;
}

.login .password_icon input {
  padding-right: 45px;
}

.login .forgot_password {
  margin-bottom: 10px;
  text-align: right;
}

.login .forgot_password a {
  font:  14px/30px Poppins;
  font-weight: 600;
  color: #5c5da1;
}

.login .privacy_policy {
  text-align: center;
  font: normal normal normal 12px/24px Poppins;
  letter-spacing: 0px;
  color: #959DB8;
  opacity: 1;
  margin-top: 50px;
}

.login .btn_login {
  background-color: #22205e;
  /* border-radius: 1px; */
  box-shadow: 0 1rem 3rem rgba(123, 101, 247, 0.5) !important;
  opacity: 1;
  color: white !important;
  outline: none !important;
  height: 50px;
  border: none !important;
}

.login .captcha {
  margin-bottom: 20px;
  background-color: #f6f7f8;
}

.backBtn-arrow {
  text-align: left;
  font: normal normal normal 14px/30px Poppins;
  letter-spacing: 0px;
  color: #32303e;
  display: inline-block;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 25px;
}

.email-text {
  color: black;
  font: 500 14px/20px Poppins;
  text-align: center;
}
@media (max-width: 1025px) {
  .form_group .password_icon1 {
    position: absolute;
    top: 303px;
    right: 597px;
  }
}

@media (max-width: 768px) {
  .form_group .password_icon1 {
    position: absolute;
    top: 322px;
    right: 243px;
  }
}

@media (max-width: 427px) {
  .form_group .password_icon1 {
    position: absolute;
    top: 289px;
    right: 64px;
  }
}
@media (max-width: 991px) {
  .btn_login {
    font: 400 14px/20px Poppins;
    padding: 10px 20px;
    margin-bottom: 1px;
    margin-top: 20px;
  }

  .login_banner_img {
    display: none;
  }

  .login_bg {
    padding: 20px;
    height: 100%;
  }

  .login {
    padding: 30px;
    width: 100%;
    max-width: 400px;
    margin: 10% auto 0;
  }

  .logo_img {
    margin-bottom: 20px;
    /* text-align: right; */
  }

  .logo_img img {
    width: 180px;
    height: 55px;
  }

  .login .title_text {
    font: bold 28px/26px Poppins;
    margin-bottom: 10px;
  }

  .login .welcome_text {
    font: 400 14px/22px Poppins;
    color: #353b3b;
    margin-bottom: 10px;
  }

  .login .form_group {
    margin-bottom: 5px !important;
  }

  .login .form_control {
    height: 40px;
  }

  .login .error_text {
    font: 500 11px/16px Poppins;
  }

  .login .password_icon img {
    top: 15px;
    right: 15px;
  }

  .login .password_icon input {
    padding-right: 35px;
  }

  .login .forgot_password {
    margin-bottom: 5px;
  }

  .login .forgot_password a {
    font: 500 11px/16px Poppins;
    color: #5c5da1;
  }

  .login .captcha {
    margin-bottom: 10px;
  }

  .login .privacy_policy {
    font: 400 11px/24px Poppins;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .login {
    padding: 105px 25px 18px 25px;
  }
}

/*# sourceMappingURL=index.css.map */
.otp_input_section {
  margin: 0 0 14px;
  position: relative;
}

.otp_input_section > div {
  display: flex;
  justify-content: space-between;
}

.otp_input_field input {
  border: 1px solid #dedede;
  border-radius: 1px;
  background: #f6f7f8;
  width: 42px !important;
  height: 44px;
}

.otp_input_field_admin input {
  width: 100% !important;
  height: 43px;
}

.otp_input_field > span {
  display: none;
}

.modal_title {
  text-align: center;
  color: #353b3b;
  font: 600 20px Poppins;
}

.sub_text {
  margin-bottom: 20px;
  text-align: center;
  color: #5c5da1;
  font: 300 16px Poppins;
}

.para p {
  font: 12px Poppins !important;
  line-height: 3px !important;
}

.para-text {
  font: 12px Poppins !important;
  line-height: 15px !important;
}

.para-text {
  margin-top: 25px;
  margin-bottom: 25px;
}
