.management_layout_bg {
  background-color: #f2f0fb;
  height: 105vh;
  width: 100%;
}
.management_layout {
  background: url(../assets/icons/bgbg.png);
  height: 100vh;
  width: 100%;
  padding: 10px 10px 10px 10px;
  overflow-x: hidden;
}
.management_contents {
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 40px;
  height: 95vh;
  overflow-y: auto;
  box-shadow: 0px 0px 16px rgb(40 41 42 / 10%);
}
.management_contents::-webkit-scrollbar {
  height: 5px !important;
  width: 2px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
/* Track */
.management_contents::-webkit-scrollbar-track {
  border-radius: 10px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

/* Handle */
.management_contents::-webkit-scrollbar-thumb {
  background: #2b308725 !important;
  border-radius: 10px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

/* Handle on hover */
.management_contents::-webkit-scrollbar-thumb:hover {
  background: #2b308725 !important;
  cursor: pointer !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.heading {
  text-align: left;
  font: normal normal 24px/30px Poppins;
  font-weight: 600;
  letter-spacing: 0px;
  color: #32303e;
}
.headingFrequentCus {
  text-align: left;
  font: normal normal 23px/30px Poppins;
  font-weight: 600;
  letter-spacing: 0px;
  margin-top: 10px;
  color: #32303e;
}
.frequent_customer {
  background: transparent;
}

.white_bg {
  background: white;
  box-shadow: 0px 5px 10px #0000000a;
  border-radius: 9px;
  height: 100px;
  padding: 15px;
  margin-top: 10px;
}
.dashboard_subheading {
  text-align: left;
  font: normal normal medium 20px/30px Poppins;
  letter-spacing: 0px;
  color: #32303e;
  width: 100% !important;
  height: 43px;
}
.proContent {
  padding-left: 15px;
}
.proContent p {
  font: normal normal normal 12px/16px Poppins;
  letter-spacing: 0px;
  color: #32303e;
  margin-bottom: 5px !important;
}
.proContent span {
  text-align: left;
  font: normal normal medium 10px/0px Poppins !important;
  letter-spacing: 0px;
  color: #32303e;
}
.proContent .spanTag {
  text-align: left;
  font: normal normal normal 10px/0px Poppins;
  letter-spacing: 0px;
  color: #959db8;
}
.spanBlack {
  text-align: left;
  font: normal normal normal 10px/20px Poppins;
  letter-spacing: 0px;
  color: #32303e;
}
.management_header_P {
  color: #959db8;
  font: normal normal normal 12px/20px Poppins;
  letter-spacing: 0px;
}
.heading2 {
  text-align: left;
  font: normal normal 600 21px/50px Poppins;
  letter-spacing: 0px;
  color: #32303e;
}
.heading2 span {
  text-align: left;
  font: normal normal 600 25px/30px Poppins;
  letter-spacing: 0px;
  color: #959db8;
}
.bluediv {
  background: #eefefe 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  margin-bottom: 10px;
}
.bluediv:hover {
  background: #eefefe 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  margin-bottom: 10px;
  box-shadow: 0px 30px 50px #22205e26;
}

.violetdiv {
  background: #f2e8ff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  margin-bottom: 10px;
}
.violetdiv:hover {
  background: #f2e8ff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  margin-bottom: 10px;
  box-shadow: 0px 30px 50px #22205e26;
}
.orangediv {
  background: #fff7e4 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  margin-bottom: 10px;
}
.orangediv:hover {
  background: #fff7e4 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  margin-bottom: 10px;
  box-shadow: 0px 30px 50px #22205e26;
}
.count {
  text-align: center;
  font: normal normal 600 23px/28px Poppins;
  letter-spacing: 0px;
  color: #32303e;
}

.countHead {
  text-align: center;
  font: normal normal normal 12px/0px Poppins;
  letter-spacing: 0px;
  color: #32303e;
  margin-bottom: 0px;
}

.countsubHead {
  text-align: center;
  font: normal normal normal 10px/0px Poppins;
  letter-spacing: 0px;
  color: #959db8;
}
.fliterHead {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
}
.menuHead {
  display: flex;
  /* position: relative;
  top: -37px; */

}
.white_bg1 {
  margin-top: 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 20px 20px 20px 20px;
  border: 1px solid #cdd0e34b;
}
.white_bg2 {
  margin-top: 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 60px 20px 20px 20px;
  border: 1px solid #cdd0e34b;
}
.white_bg1:hover {
  margin-top: 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 30px 50px #22205e26;
  border-radius: 5px;
  border: 1px solid #cdd0e34b;
  padding: 20px 20px 20px 20px;
}
.white_bg2:hover {
  margin-top: 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 30px 50px #22205e26;
  border-radius: 5px;
  border: 1px solid #cdd0e34b;
  padding: 60px 20px 20px 20px;
}
.dashboard_card {
  background-color: #ffffff;
  border: 1px solid #cdd0e34b;
  border-radius: 5px;
  padding: 20px;
  margin-top: 25px;
}
.dashboard_card:hover {
  background-color: #ffffff;
  border: 1px solid #cdd0e34b;
  border-radius: 5px;
  box-shadow: 0px 30px 50px #22205e26;
  padding: 20px;
  margin-top: 25px;
}
.dashboard_card_title {
  font: normal normal 600 23px/28px Poppins;
  color: #32303e;
}
.most_viewed_products_item {
  align-items: center;
  margin-bottom: 20px;
}

.most_viewed_products_item img {
  width: 60px;
  height: 60px;
  box-sizing: content-box;
  border: 1px solid #ebebeb;
  border-radius: 10px;
}

.most_viewed_products_item h4 {
  font: normal normal normal 12px/20px Poppins;
  color: #32303e;
  margin-bottom: 0px;
  /* text-overflow: ellipsis; */
  overflow: hidden;
  white-space: nowrap;
  /* max-width: 110px; */
}

.most_viewed_products_item p {
  font: normal normal normal 10px/10px Poppins;
  letter-spacing: 0px;
  color: #959db8;
}

.most_viewed_products_contents {
  padding-left: 15px;
  letter-spacing: 0px;
  color: #32303e;
}
.dashboard_seeMore {
  float: right;
  cursor: pointer;
  position: relative;
  right: 10px;
  top: -45px;
  text-decoration: underline;
  font: normal normal medium 12px/24px Poppins;
  letter-spacing: 0px;
  color: #22205e;
}
.dashboard_seeMoreTwo {
  cursor: pointer;
  text-decoration: underline;
  font: normal normal medium 12px/24px Poppins;
  letter-spacing: 0px;
  color: #22205e;
  text-align: center;
}
.filterDesign {
  background: #eceef5 0% 0% no-repeat padding-box !important;
  border-radius: 4px;
  width: auto;
  height: 32px;
  border: none !important;
  padding-left: 5px;
  padding-right: 5px;
}
.filterDesignTwo {
  background: #eceef5 0% 0% no-repeat padding-box !important;
  border-radius: 4px;
  width: auto;
  height: 32px;
  border: none !important;
  padding-left: 5px;
  padding-right: 5px;
}

/* .dashboard_subheading {
  text-align: left;
  font: normal normal medium 20px/30px Poppins;
  letter-spacing: 0px;
  color: #32303e;
  width: 100px !important;
  height: 43px;
} */
.dashboard_subheading_head {
  text-align: left;
  font: normal normal medium 20px/30px Poppins;
  letter-spacing: 0px;
  color: #32303e;
}
.dashboard_subheadingTwo {
  text-align: left;
  font: normal normal medium 20px/30px Poppins;
  letter-spacing: 0px;
  color: #32303e;

  height: 43px;
  position: relative;
  top: -45px;
  left: 2px;
}
@media (max-width: 1024px) and (min-width: 770px) {
  .loadingPage {
    margin-top: 40vh;
    margin-left: 70vh !important;
  }

  .menuHead {
  
    display: flex !important;
    padding: 0px;
    
  }
}
@media (max-width: 769px) and (min-width: 430px) {
  .loadingPage {
    margin-top: 40vh;
    margin-left: 75vh !important;
  }
  .management_layout {
    background: url(../assets/icons/dashboardImg.svg);
    height: 100vh;
    width: 100%;
    padding: 10px 10px 10px 10px;
    overflow-x: hidden;
  }
  .heading {
    text-align: left;
    font: normal normal 24px/30px Poppins;
    font-weight: 600;
    letter-spacing: 0px;
    color: #32303e;
    padding-top: 10px;
  }
  .dashboard_seeMoreTwo {
    float: right;
    cursor: pointer;
    position: relative;
    right: 235px;
    top: 10px;
    text-decoration: underline;
    font: normal normal medium 12px/24px Poppins;
    letter-spacing: 0px;
    color: #22205e;
    padding-bottom: 25px;
  }
}
@media (max-width: 426px) {
  .loadingPage {
    margin-top: 40vh;
    margin-left: 25vh !important;
  }
  .heading {
    text-align: left;
    font: normal normal 24px/30px Poppins;
    font-weight: 600;
    letter-spacing: 0px;
    color: #32303e;
    padding-top: 10px;
  }
  .dashboard_card_title {
    font: normal normal 600 18px/28px Poppins;
    color: #32303e;
  }
  .dashboard_seeMore {
    float: right;
    cursor: pointer;
    position: relative;
    right: 10px;
    top: -41px;
    text-decoration: underline;
    font: normal normal medium 12px/24px Poppins;
    letter-spacing: 0px;
    color: #22205e;
  }

  .dashboard_subheadingTwo {
    text-align: center;
    font: normal normal medium 20px/30px Poppins;
    letter-spacing: 0px;
    color: #32303e;
    padding-top: 20px;
    height: 43px;
    position: relative;
    top: -45px;
    left: 2px;
  }

  .dashboard_seeMoreTwo {
    float: right;
    cursor: pointer;
    position: relative;
    right: 170px;
    top: 10px;
    text-decoration: underline;
    font: normal normal medium 12px/24px Poppins;
    letter-spacing: 0px;
    color: #22205e;
    padding-bottom: 25px;
  }
}
@media (max-width: 376px) {
  .loadingPage {
    margin-top: 40vh;
    margin-left: 22vh !important;
  }
}
@media (max-width: 321px) {
  .loadingPage {
    margin-top: 40vh;
    margin-left: 15vh !important;
  }
}
.loadingPage {
  margin-top: 40vh;
  margin-left: 70vh;
}
.filterDesignOption {
  background-color: white !important;
  color: black !important;
  border: none !important;
  cursor: pointer;
}
.filterDesignOption:hover {
  background-color: #22205e !important;
  color: white !important;
  border: none !important;
  cursor: pointer;
}
.filterDesignOption:active {
  background-color: #22205e !important;
  color: white !important;
  border: none !important;
  cursor: pointer;
}

@media (max-width: 1028px) {
  /* .dashboard_subheading {
    text-align: left;
    font: normal normal medium 20px/30px Poppins;
    letter-spacing: 0px;
    color: #32303e;
    width: 100% !important;
    height: 30px !important;
    display: block !important;
  } */
  .fliterHead {
    display: block !important;
    justify-content: space-between;
    padding-bottom: 50px;
  }
}
