/* heading */
.management_contents {
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border-radius: 40px;
  padding: 30px;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  box-shadow: 0px 0px 16px rgb(40 41 42 / 10%);
}

.management_contents::-webkit-scrollbar {
  width: 2px;
}
/* Track */
.management_contents::-webkit-scrollbar-track {
  border-radius: 1rem;
}

/* Handle */
.management_contents::-webkit-scrollbar-thumb {
  background: #bcbbba;
  border-radius: 1rem;
}

/* Handle on hover */
.management_contents::-webkit-scrollbar-thumb:hover {
  background: #6d676e;
}

.form.management_contents {
  padding: 50px;
}

select:focus-visible {
  outline: none !important;
}

/* Form Control */
.form_control {
  border: 1px solid #cdd0e3 !important;
  border-radius: 10px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  font-family: "Poppins", inherit !important;
  background-color: #ffffff;
}

.listWidth {
  display: inline-block;
  width: 20px;
}

.dropdown .form_control {
  border: 1px solid #ffffff !important;
  border-radius: 5px !important;
  font-size: 14px !important;

  line-height: 20px !important;
  font-family: "Poppins", inherit !important;
  background-color: #ffffff;
}

.form_control::placeholder {
  font-size: 12px;
  color: #b9b9b9;
}

.management_contents .management_details .management_details_contents label {
  font: 500 12px/20px Poppins;
  color: #2e2e2e;
}

.form_control:focus {
  box-shadow: none !important;
  border-color: #cdd0e34b !important;
}

/* Form control end */

.page_item:disabled {
  background-color: transparent !important;
}
.management_header {
  align-items: center;
  padding-bottom: 20px;
}

.management_header_heading {
  font: 600 24px/53px Poppins !important;
  color: #32303e !important;
  margin-bottom: 0 !important;
  margin-left: 15px !important ;
  display: flex;
  margin-right: auto;
}
.management_header_heading1 {
  font: 600 24px/53px Poppins !important;
  color: #32303e !important;
  margin-bottom: 0 !important;
  margin-left: 10px !important ;
  display: flex;
  margin-right: auto;
}
.management_header_P {
  margin-left: 11px;
  color: #959db8;
  font: normal normal normal 12px/20px Poppins;
  letter-spacing: 0px;
}

.form_control::placeholder {
  font: 12px/53px Poppins !important;
}

/* Search Bar */
.management_contents_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.management_contents_search_bar {
  max-width: 550px;
  width: 100%;
  position: relative;
}

.management_contents_search_bar > .form_control {
  border: 1px solid #ECEEF5 !important;
  border-radius: 7px !important;
  padding-left: 35px;
  font: 500 10px/10px Poppins;
  height: calc(2.25rem + 2px) !important;
  color: #6d6d6d;
  background-color: white;
}

.management_contents_search_bar img {
  position: absolute;
  top: 10px;
  left: 12px;
  z-index: 999;
}

.management_contents_search_sale {
  justify-content: end;
  margin-bottom: 15px;
}

.management_contents_search_sale .management_contents_search_bar {
  width: 100%;
  max-width: none;
}

.management_contents_search_bar .form_control::placeholder {
  font-size: 12px;
}

.management_contents_search_bar input {
  background: #f0f0f4;
}

/* Search bar end */

/* Table */
.management_table table {
  border-collapse: separate;
  border-spacing: 0px 15px;
}

.management_table table th {
  font: 400 14px/20px Poppins;
  color: #32303e;
  white-space: nowrap;
}

.management_contents .management_table table tbody tr {
  background-color: #fff;
  margin-bottom: 0px !important;
  cursor: pointer;
  border: 1px solid #eaf0f7 !important;
}
.management_contents .management_table table tbody tr:hover {
  background-color: #fff;
  margin-bottom: 3px !important;
  cursor: pointer;
  box-shadow: 4px 7px 7px -6px #22205e26;
  border-radius: 37px;
  border: 1px solid #eaf0f7;
}
.management_layout {
  background: #fcfcfc 0% 0% no-repeat padding-box;
  padding: 10px 10px 10px 10px;
}
.management_table table tbody td {
  font: normal normal normal 12px/25px Poppins;
  color: #32303e;
  vertical-align: middle !important;
  white-space: nowrap;
}
.order_table .ant-table-tbody > tr:first-child {
  margin-top: 20px !important;
}

.order_table .ant-table-tbody > tr {
  margin-top: 20px !important;
}
.management_table table tbody td .admin_table_img {
  height: 50px;
  width: 50px;
  padding: 6px;
  box-sizing: content-box;
  border: 1px solid #ebebeb;
  border-radius: 5px;
}

.table_footer .pagination .page_item .page_link {
  font: 500 12px/20px Poppins !important;
  padding: 5px 10px !important;
  color: #2e2e2e !important;
  background-color: transparent !important;
  border: none !important;
}

.management_table table tbody td .admin_table_img.admin_table_avatar {
  height: 50px;
  width: 50px;
  padding: 0px;
  border: none;
  box-sizing: content-box;
  border-radius: 50%;
}

.management_table table tbody td .admin_table_edit_img {
  height: 22px;
  width: 22px;
}

.management_table table tbody td:first-child {
  border-radius: 15px 0 0 15px;
}

.management_table table tbody td:last-child {
  border-radius: 0 15px 15px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 160px;
  padding-right: 10px;
}
/* Table End */

/* Table Footer and pagination*/
.table_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table_footer .table_pages .form_control {
  width: auto !important;
  display: inline-block;
  border: 1px solid #cbcbcb !important;
  border-radius: 6px !important;
  padding: 7px 8px 4px !important;
  background-color: transparent !important;
  height: auto !important;
}

.table_footer .table_pages span {
  font: 500 12px/20px Poppins;
  color: #32303e;
  margin-left: 15px;
}

.table_footer .pagination .page_item {
  font: 500 12px/20px Poppins !important;
  padding: 5px 1px !important;
  color: #2e2e2e !important;
  background-color: transparent !important;
  border: none !important;
}

.table_footer .pagination .page_item .page_link:hover,
.table_footer .pagination .page_item .page_link:active,
.table_footer .pagination .page_item .page_link_active {
  background-color: #22205e !important;
  color: #fff !important;
  border: 1px solid #cdd0e34b;
  border-radius: 6px;
}



.table_footer .pagination .page_item .page_link:focus {
  outline: none !important;
}

/* Table Footer End*/

/*Antd Custom Changes */
.ant-modal {
  max-width: unset;
  margin: unset;
  max-height: unset;
}

.ant-modal-centered::before {
  content: unset;
}

.filter_dropdown {
  border: none;
  border-radius: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cdd0e34b;
  padding: 7px 10px;
  font: 500 12px/18px Poppins;
}

.customer_dropdown .dropdown .form_control {
  display: inline;
  width: 100%;
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #32303e;
  padding: 5px;
  height: auto;
  box-shadow: 0px 10px 20px #0000000d;
  border: 1px solid #cdd0e34b;
  border-radius: 5px;
}

.dropdown {
  position: relative;
  top: -14px;
  right: 13px;
}

.dropdown .filter_dropdown .card_contents {
  padding: 0;
  width: 0px !important;
  background: transparent;
  box-shadow: none;
}
.detailHeader {
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 30px 50px #22205e26;
  border-radius: 4px;
}
.detailHeader p {
  text-align: left;
  font: normal 12px/24px Poppins;
  letter-spacing: 0px;
  color: #32303e;
}
.detailHeader span {
  text-align: left;
  font: normal normal normal 8px/16px Poppins;
  letter-spacing: 0px;
  color: #959db8;
}
.userDetail_Name {
  width: 145px;
  display: inline-block;
}
.userDetail_id {
  width: 200px;
  display: inline-block;
}
.userDetail_website {
  width: 70px;
  display: inline-block;
}
.userDetail_location {
  width: 100px;
  display: inline-block;
}
.userDetail_last {
  width: 175px;
  display: inline-block;
}
.userDetail_total {
  width: 100px;
  display: inline-block;
}
.userDetail_item {
  width: 100px;
  display: inline-block;
}

.management_contents_product {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 15px 15px 26px 15px;
  max-height: auto;
  margin-bottom: 10px;
}
.management_contents_product:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 15px 15px 26px 15px;
  box-shadow: 0px 4px 26px #22205e26;
  max-height: auto;
  margin-bottom: 10px;
}
.management_contents_product_Userbg {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0px 30px 50px #22205e26;
  margin-bottom: 20px;
  height: 110px;
}
.management_contents_product_Userbg p {
  text-align: left;
  font: normal normal normal 12px/24px Poppins;
  letter-spacing: 0px;
  color: #32303e;
  opacity: 1;
  margin-bottom: 0px !important;
}

.management_contents_product_Userbg h1 {
  text-align: left;
  font: normal normal normal 10px/20px Poppins;
  letter-spacing: 0px;
  color: #959db8;
  opacity: 1;
}
.management_contents_product p {
  text-align: left;
  font: normal normal normal 12px/24px Poppins;
  letter-spacing: 0px;
  color: #32303e;
  opacity: 1;
}

.management_contents_product h1 {
  text-align: left;
  font: normal normal normal 10px/20px Poppins;
  letter-spacing: 0px;
  color: #959db8;
  opacity: 1;
  padding-right: 10px;
}
.toalVisits {
  position: relative;
}
.totalIcons {
  position: relative;
  top: -27px;
  right: -50px;
}
@media (max-width: 1025px) {
  .loadingPage {
    margin-top: 40vh;
    margin-left: 70vh !important;
  }
  
}
@media (max-width: 769px) {
  .loadingPage {
    margin-top: 40vh;
    margin-left: 40vh !important;
  }
}
@media (max-width: 426px) {
  .loadingPage {
    margin-top: 40vh;
    margin-left: 25vh !important;
  }
}
@media (max-width: 376px) {
  .loadingPage {
    margin-top: 40vh;
    margin-left: 22vh !important;
  }
}
@media (max-width: 321px) {
  .loadingPage {
    margin-top: 40vh;
    margin-left: 15vh !important;
  }
}
.loadingPage {
  margin-top: 30vh;
  margin-left: 70vh;
}
.hide {
  display: none;
}
    
.user_image:hover + .hide {
  display: block;
  color: red;
}
.btntoggle {
  float: right;
  margin-top: 10px;
  margin-bottom: 20px;
}