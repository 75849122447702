@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Regular.ttf');
    src: url('../fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('../fonts/Poppins-SemiBold.ttf');
    src: url('../fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: bold;
   font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins-Bold';
    src: url('../fonts/Poppins-Bold.ttf');
    src: url('../fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: bold;
    font-display: swap;
}
/* @font-face {
    font-family: 'JosefinSans';
    src: url('../fonts/JosefinSans-Regular.ttf');
    src: url('../fonts/JosefinSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
} */